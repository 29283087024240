<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title class="capitalize">configuración presupuesto</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-tabs v-model="tab" start>
      <v-tab> Presupuesto </v-tab>
      <v-tab> KPI Presupuesto </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <!-- PRESUPUESTO TAB -->

      <v-row class="ma-0 pa-0">
        <v-col
          class="ma-0 px-6 pt-4"
          cols="6"
          xs="6"
          sm="6"
          md="6"
          lg="6"
          xl="6"
          justify="center"
          align="center"
          style="color: #333333"
        >
          <v-tab-item>
            <v-text-field :value="initial" label="Presupuesto Inicial" disabled></v-text-field>

            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="updated"
                :rules="numberRule"
                outlined
                label="Presupuesto Actualizado"
                required
              ></v-text-field>
            </v-form>

            <v-text-field :value="balance" label="Saldo Actualizado" disabled></v-text-field>

            <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate"> Guardar </v-btn>

            <v-btn color="error" class="mr-4" @click="reset"> Limpiar </v-btn>
          </v-tab-item>
          <!-- KPI TAB  -->
          <v-tab-item>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="nombre"
                :counter="10"
                :rules="nameRules"
                label="Nombre KPI"
                required
              ></v-text-field>

              <v-text-field v-model="descripcion" label="Descripción" required></v-text-field>

              <v-select
                v-model="tipoKPI"
                :items="kpis"
                :rules="[(v) => !!v || 'Requerido']"
                label="Tipo KPI"
                required
                dense
                solo
              ></v-select>

              <v-text-field v-model="minimo" :counter="10" :rules="nameRules" label="Mínimo" required></v-text-field>

              <v-text-field v-model="maximo" :counter="10" :rules="nameRules" label="Máximo" required></v-text-field>

              <v-select
                v-model="tramo"
                :items="tramos"
                :rules="[(v) => !!v || 'Item is required']"
                label="Tramo"
                required
                dense
                solo
              ></v-select>
              <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate"> Guardar </v-btn>

              <v-btn color="error" class="mr-4" @click="reset"> Limpiar </v-btn>

              <v-btn color="warning" @click="back"> Volver </v-btn>
            </v-form>

            <v-card>
              <v-card-title>
                KPI Agregados
                <v-spacer></v-spacer>
              </v-card-title>
              <v-data-table
                :headers="dataTable.headers"
                :items="dataTable.desserts"
                hide-details
                hide-default-footer
              ></v-data-table>
            </v-card>
          </v-tab-item>
        </v-col>
      </v-row>
    </v-tabs-items>
  </v-card>
</template>

<script>
import BudgetService from "@/services/pmo/budget/budgetService";

export default {
  data: () => ({
    nombre: "",
    descripcion: "",
    tipoKPI: null,
    minimo: 0,
    maximo: 100,
    initial: 0,
    updated: 0,
    balance: 0,
    dataTable: {
      search: "",
      headers: [
        { text: "Nombre", value: "nombre" },
        { text: "Descripción", value: "descripcion" },
        { text: "Tipo KPI", value: "tipo" },
        { text: "Valor Mínimo", value: "minimo" },
        { text: "Valor Máximo", value: "maximo" },
      ],
      desserts: [
        {
          nombre: "KPI 1",
          descripcion: "Evaluación de Riesgo",
          tipo: "Tipo 1",
          minimo: 2.0,
          maximo: 7.0,
        },
        {
          nombre: "KPI 2",
          descripcion: "Impacto Cliente",
          tipo: "Tipo 3",
          minimo: 4.0,
          maximo: 6.0,
        },
      ],
    },

    tab: null,
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    numberRule: [(v) => !!v || "Favor ingrese un valor", (v) => !isNaN(v) || "Un valor entero menos de 10 dígitos"],
    email: "",
    emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    select: null,
    kpis: ["KPI 1", "KPI 2", "KPI 3", "KPI 4"],
    tramos: ["Tramo 1", "Tramo 2", "Tramo 3", "Tramo 4"],
    checkbox: false,
  }),

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      let balance = this.balance;
      let initial = this.initial;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.balance = balance;
      this.initial = initial;
    },
    back() {
      this.$router.push({ name: "pmo-digital-budget" });
    },
  },
  mounted() {
    BudgetService.getInitialAndBalance()
      .then((data) => {
        this.balance = this.$options.filters.currency(data.balance);
        this.initial = this.$options.filters.currency(data.initial);
      })
      .catch((err) => console.dir(err));
  },
};
</script>

<style scoped>
.capitalize {
  color: #999999;
  text-transform: capitalize;
  font-size: 48px;
}
</style>
